<template>
  <a-spin :spinning="spinning">
    <a-card :border="false">
      <div class="realtor">
        <!-- 查询表单 -->
        <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
          <a-row :gutter="8">
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="大区事务所">
                <a-cascader
                  :options="dealerList"
                  v-model="regionalOffice"
                  change-on-select
                  placeholder="请选择大区/事务所"
                  @change="onChange"
                  :field-names="{ label: 'title', value: 'id', children: 'children' }"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
              <a-form-model-item label="经销商">
                <a-input v-model="searchData.dealerName" placeholder="请输入经销商名称" allowClear></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="8" :xl="6" :xxl="6" :sm="12">
<!--              <a-form-model-item label="账期">-->
<!--                <a-month-picker placeholder="选则账期" />-->
<!--              </a-form-model-item>-->
              <a-form-model-item label="对账月份">
                <MonthPicker :startTime.sync="searchData.checkTime" />
              </a-form-model-item>
            </a-col>
            <a-col :xxl="6" :xl="5" :md="8" sm="2">
              <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
                <a-button type="primary" @click="query()" icon="search">查询</a-button>
                <a-button type="default" @click="reset()" icon="sync">重置</a-button>
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
        <div>
          <a-button v-if="isCheck" type="primary" @click="hanlderTest('check')"> 查看 </a-button>
<!--          <a-button type="primary" @click="hanlderTest('toExamine')">审核</a-button>-->
<!--          <downLoad-->
<!--            method="get"-->
<!--            api="/api/order/order/orderInfo/exportSingleOrderInfo"-->
<!--            :params="{ id: 11 }"-->
<!--            name="导出.xlsx"-->
<!--            >导出</downLoad-->
<!--          >-->
        </div>
        <!-- 数据表格 -->
        <a-table
          :loading="tableLoading"
          :columns="columns"
          :data-source="tableData"
          :rowKey="(record) => record.id"
          :row-selection="rowSelection"
          bordered
          @change="changeTable"
          :pagination="page"
        >
          <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
          <span slot="confirmStatus" slot-scope="text">
            <a-tag color="gray" v-if="text == 0">未确认</a-tag>
            <a-tag color="pink" v-if="text == 1">相符</a-tag>
            <a-tag color="green" v-if="text == 2">不相符</a-tag>
          </span>
          <span slot="oweAmount" slot-scope="text,record">
            <span class="TextRed" @click="handlerTableClick('TextRed',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="totalAmount" slot-scope="text">
            <span class="DarkGreen">{{ text | formatMoney }}</span>
          </span>
          <span slot="balanceAmount" slot-scope="text,record">
            <span class="ShallowGreen" @click="handlerTableClick('ShallowGreen',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="limitAmount" slot-scope="text,record">
            <span class="ShallowGreen" @click="handlerTableClick('ShallowGreenXY',record)">{{ text | formatMoney }}</span>
          </span>
          <span slot="CKDZD" slot-scope="text,record">
            <span class="DarkBule" @click="handlerTableClick('DarkBule',record)">对账单</span>
          </span>
          <div slot="statementFile" slot-scope="text" class="img-list" @click.stop="handlerEnlarge">
            <img
              width="100%"
              height="100%"
              :src="text"
              alt="无账单附件"
            />
            <!-- https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png -->
          </div>
        </a-table>
        <AccountCheckModal ref="accountCheckModal" />
        <ToExamine ref="ToExamine" />
        <Overview ref="overview" />
        <ZJAccountInfo ref="ZJAccountInfo" />
        <XYAccountInfo ref="XYAccountInfo" />
        <QKAccountInfo ref="QKAccountInfo" />
      </div>
    </a-card>
  </a-spin>
</template>

<script>
import { WQEolumns } from './thead/colums'
import { mixin } from './components/mixin/insert'
import { listDealerStatement } from './api/DealerStatementApi'
import {checkPermission} from "@/utils/permissions";

export default {
  name: 'accountToExamine',
  mixins: [mixin],
  components: {
    AccountCheckModal: () => import('./components/accountCheckModal.vue'),
    ToExamine: () => import('./components/handler/toExamine.vue'),
    Overview: () => import('./components/table/overview.vue'),
    ZJAccountInfo: () => import('./components/info/ZJAccountInfo.vue'),
    XYAccountInfo: () => import('./components/info/XYAccountInfo.vue'),
    QKAccountInfo: () => import('./components/info/QKAccountInfo.vue'),
  },
  data() {
    return {
      columns: WQEolumns,
      tableData: [],
      selectedRowKeys: [],
      tableLoading: false, //表格loading
      visible: false,
      visibleAll: false,
      loading: false,
      spinning: false,
      isCheck: checkPermission('toExamine:statement:list:check'),
    }
  },

  methods: {
    getData() {
      this.tableLoading = true
      this.selectedRowKeys = []
      this.selectedRows = []
      this.searchData.flagConfirm = false
      listDealerStatement({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.searchData,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
        .finally(() => (this.tableLoading = false))
    },
    handlerTableClick(type,row) {
      if (type === 'TextRed') {
        if (this.$refs.QKAccountInfo) {
          this.$refs.QKAccountInfo.init(row)
        }
      }
      if (type === 'ShallowGreen') {
        if (this.$refs.ZJAccountInfo) {
          this.$refs.ZJAccountInfo.init(row)
        }
      }
      if (type === 'ShallowGreenXY') {
        if (this.$refs.XYAccountInfo) {
          this.$refs.XYAccountInfo.init(row)
        }
      }
      if (type === 'DarkBule') {
        if (this.$refs.overview) {
          this.$refs.overview.setRowData(row)
        }
      }
    },
    hanlderTest(name) {
      const _this = this
      if (_this.selectedRows.length === 0) {
        return _this.$message.warning('请选择一条记录')
      }
      if (name === 'check') {
        if (_this.selectedRows.length === 1) {
          _this.$refs.accountCheckModal.setRowData(_this.selectedRows[0], 'check')
          return
        } else {
          _this.$message.warning('请选择一条记录')
        }
      }
      if (name === 'toExamine') {
        if (_this.$refs.ToExamine) {
          _this.$refs.ToExamine.init('confirm')
        }
        return
      }
      if (name === 'rollbackStatus') {
        _this.$confirm({
          title: '撤审警告',
          content: '确认要撤回此条记录的审核吗?',
          okText: '确认',
          cancelText: '取消',
        })
        return
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import './style/main.less';
</style>
